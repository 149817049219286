<template>

  <t-form-card
    :title="`Contato`"
    :edit="true"> <!-- setado true para não edição -->
    <template v-slot:content>
      <div class="content">
        <div class="fields">
          <div class="sixteen wide field">
            <div :class="[ 'field', { 'error': !$v.donor.email.email } ]">
              <label for="donorEmail" class="label">Email</label>
              <input type="text" id="donorEmail" name="donor[email]"
                :class="['ui input', { 'disabled': !editForm }]"
                :disabled="!editForm"
                placeholder="Email"
                v-model="donor.email">
              <label for="authEmail" class="validate error" v-if="submited && !$v.donor.email.email">O <strong>E-mail</strong> é inválido.</label>
            </div>
            <div :class="[ 'field', { 'disabled': !editForm } ]">
              <label for="donorMobilePhone" class="label">Telefone Celular</label>
              <the-mask
                id="donorMobilePhone"
                name="donor[mobilephone]"
                :mask="['(##) ####-####', '(##) #####-####']"
                v-model="mobilePhone"
                :masked="false"
                placeholder="Telefone Celular"
                :class="[{ 'disabled': !editForm }]"
                :disabled="!editForm"/>
            </div>
            <div :class="[ 'field', { 'disabled': !editForm } ]">
              <label for="donorPhoneNumber" class="label">Telefone Fixo</label>
              <the-mask
                id="donorPhoneNumber"
                name="donor[phonenumber]"
                :mask="['(##) ####-####']"
                v-model="phoneNumber"
                :masked="false"
                placeholder="Telefone Fixo"
                :class="[{ 'disabled': !editForm }]"
                :disabled="!editForm" />
            </div>
            <div :class="[ 'field', { 'disabled': !editForm } ]">
              <label for="donorComercialNumber" class="label">Telefone Comercial</label>
              <the-mask
                id="donorComercialNumber"
                name="donor[comercialnumber]"
                :mask="['(##) ####-####', '(##) #####-####']"
                v-model="comercialPhone"
                :masked="false"
                placeholder="Telefone Fixo"
                :class="[{ 'disabled': !editForm }]"
                :disabled="!editForm" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="false" v-slot:actions>
      <div class="actions" v-show="editForm">
        <button class="tui tuim ui button" @click.prevent="resetForm">Cancelar</button>
        <button class="tui tuim ui primary button" @click.prevent="save">Salvar</button>
      </div>
    </template>
  </t-form-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { email } from 'vuelidate/lib/validators'

export default {
  name: "TCardContact",
  components: { TFormCard: () => import('@/components/card/index.vue') },
  data() {
    return {
      submited: false
    }
  },
  computed: {
    ...mapState({
      donor: ({ donors }) => donors.donorDetail,
      editForm: ({ donors }) => donors.editForm
    }),
    mobilePhone: {
      get({ donor }) {
        const mobilePhone = donor.phones ? donor.phones.find( phone => phone.phoneType === "celphone" ) : null
        return mobilePhone ? mobilePhone.number : ""
      },
      set(value) {
        let mobilePhone = this.donor.phones ? this.donor.phones.find( phone => phone.phoneType === "celphone" ) : null
        if (!mobilePhone) {
          mobilePhone = { phoneType: 'celphone' }
          this.donor.phones.push(mobilePhone)
        }
        mobilePhone.number = value
        this.setMobilePhone(mobilePhone)
      }
    },
    phoneNumber: {
      get({ donor }) {
        const phoneNumber = donor.phones ? donor.phones.find( phone => phone.phoneType === "residential" ) : null
        return phoneNumber ? phoneNumber.number : ""
      },
      set(value) {
        let phoneNumber = this.donor.phones ? this.donor.phones.find( phone => phone.phoneType === "residential" ) : null
        if (!phoneNumber) {
          phoneNumber = { phoneType: 'comercial' }
          this.donor.phones.push(phoneNumber)
        }
        phoneNumber.number = value
        this.setResidentialPhone(phoneNumber)
      }
    },
    comercialPhone: {
      get({ donor }) {
        const comercialPhone = donor.phones ? donor.phones.find( phone => phone.phoneType === "comercial" ) : null
        return comercialPhone ? comercialPhone.number : ""
      },
      set(value) {
        let comercialPhone = this.donor.phones ? this.donor.phones.find( phone => phone.phoneType === "comercial" ) : null
        if (!comercialPhone) {
          comercialPhone = { phoneType: 'comercial' }
          this.donor.phones.push(comercialPhone)
        }
        comercialPhone.number = value
        this.setComercialPhone(comercialPhone)
      }
    }
  },

  validations: {
    donor: {
      email: { email }
    }
  },

  methods: {
    ...mapActions('donors', [ 'setComercialPhone', 'setResidentialPhone', 'setMobilePhone' ]),
  }
}
</script>
